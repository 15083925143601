import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/dist";
import BigNumber from "bignumber.js";
import { BiLockAlt } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

import axios from "../../../axios";
import {
  BtnLoader,
  ConfirmPinModal,
  PayableCurrencySelect,
  PaymentConfirmSection,
} from "../../../components";
import SinglePaymentLinkOthers from "./SinglePaymentLinkOthers";
import { getFormatedDate } from "../../../utils";
import { successAnim } from "../../../assets/gif";
import { updateRecentActivities } from "../../../redux/slices/userSlice";
import { updateBalance } from "../../../redux/slices/currenciesSlice";
import { useBalance, usePrice } from "../../../hooks";
import usePaymentLinkAmount from "../hooks/usePaymentLinkAmount";

export default function SinglePaymentLinkForm({ paymentLink }) {
  const [transactionError, setTransactionError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [note, setNote] = useState("");
  const [section, setSection] = useState(1);
  const [isConfirmPinModalOpen, setIsConfirmPinModalOpen] = useState(false);
  const [transactionResp, setTransactionRes] = useState({});

  const { currentAccount, jwtToken, user, isLoggedIn } = useSelector(
    (state) => state.user
  );
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const price = usePrice(selectedCurrency);
  const balance = useBalance(selectedCurrency?._id);
  const amount = usePaymentLinkAmount(paymentLink, selectedCurrency, price);

  const handleNextClick = () => {
    if (!isLoggedIn) {
      navigate(`/auth/login?redirect=/link/${id}`);
      return;
    }

    setSection(2);
  };

  const handleProceed = () => {
    if (!isLoggedIn) {
      navigate(`/auth/login?redirect=/link/${id}`);
      return;
    }

    setIsConfirmPinModalOpen(true);
  };

  const handleSubmit = async (pinNumber) => {
    try {
      if (!isLoggedIn) {
        navigate(`/auth/login?redirect=/link/${id}`);
        return;
      }

      setIsLoading(true);
      setTransactionError("");
      const response = await axios.post(
        `/payments/link/${id}/hamipay`,
        {
          currencyId: selectedCurrency._id,
          senderAccountId: currentAccount?._id,
          note,
          pinNumber,
          amount: amount,
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      dispatch(updateRecentActivities(response.data));
      dispatch(
        updateBalance({
          ...response?.data,
          isSender: user._id === response.data?.sender?._id,
        })
      );

      setTransactionRes(response?.data);
      setSection(3);
    } catch (err) {
      setTransactionError(
        err?.response?.data?.error || "Something went wrong, Try again"
      );
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="px-5 md:px-0">
      <div className={section === 1 ? "block" : "hidden"}>
        <div>
          <PayableCurrencySelect
            currencies={paymentLink?.acceptedCurrencies}
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
          />
        </div>
        <div className="mt-4">
          <div className="">
            <label htmlFor="">Amount</label>
            <input
              type="number"
              className={
                "arrow-hidden font-medium h-[45px] " +
                (selectedCurrency?._id &&
                (!balance || Number(balance) < Number(amount))
                  ? "border-red-500"
                  : "")
              }
              placeholder="0.00"
              value={amount || ""}
              disabled
            />
          </div>
          <span className="block text-[13px] text-grayColor mt-[5px]">
            Slippage tolerance is applied
          </span>
        </div>
        <div className="mt-4">
          <label htmlFor="">Note (optional)</label>
          <input
            type="text"
            placeholder="Enter a Note"
            className=""
            onChange={(e) => setNote(e.target.value)}
          />
        </div>
      </div>

      <div className={section === 2 ? "block" : "hidden"}>
        <PaymentConfirmSection
          setSection={setSection}
          amount={amount}
          selectedCurrency={selectedCurrency}
          receiver={paymentLink?.creator}
          balance={balance}
        />
      </div>

      {(section === 1 || section === 2) && (
        <>
          <div className="mt-5">
            {transactionError && (
              <span className="block mb-3 text-[13px] text-red-500 font-medium">
                {transactionError}
              </span>
            )}
            <span className="block text-center text-grayColor dark:text-darkGrayColor text-[12px] mb-2">
              This payment is secured by LightningPay.
            </span>
            {section === 1 ? (
              <button
                className="w-[100%] hover:bg-btnHoverColor p-0 flex items-center justify-center gap-[10px] text-[14px] font-[500] uppercase disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor"
                onClick={handleNextClick}
                disabled={
                  isLoggedIn &&
                  (Object.keys(selectedCurrency).length < 1 ||
                    !balance ||
                    Number(balance) < Number(amount))
                }
              >
                <>
                  <BiLockAlt />
                  Next
                </>
              </button>
            ) : (
              <button
                className="w-[100%] hover:bg-btnHoverColor p-0 flex items-center justify-center gap-[10px] text-[14px] font-[500] uppercase disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor"
                onClick={handleProceed}
                disabled={
                  isLoading ||
                  Number(balance) <
                    BigNumber(amount)
                      .multipliedBy(0.5)
                      .dividedBy(100)
                      .plus(amount)
                      .toNumber()
                }
              >
                {isLoading ? (
                  <BtnLoader />
                ) : (
                  <>
                    <BiLockAlt />
                    Proceed
                  </>
                )}
              </button>
            )}

            {isConfirmPinModalOpen && (
              <ConfirmPinModal
                setIsConfirmPinModalOpen={setIsConfirmPinModalOpen}
                handleSubmit={handleSubmit}
              />
            )}
            <button
              type="button"
              className="w-[100%] bg-green-500 mt-4 p-0 flex items-center justify-center gap-[10px] text-[14px] font-[500]"
            >
              Pay with Lightning Wallet
            </button>
          </div>
          <div className="mt-7">
            <SinglePaymentLinkOthers />
          </div>
        </>
      )}

      <div className={section === 3 ? "block" : "hidden"}>
        <div className="w-[140px] h-[140px] mx-auto">
          <img src={successAnim} alt="" className="w-[100%] h-[100%]" />
        </div>
        <h3 className="font-[600] text-xl text-center mt-3">Success !</h3>
        <span className="text-sm text-grayColor dark:text-darkGrayColor leading-[28px] block mt-2 text-center">
          You have successfully transfered {transactionResp?.amount}{" "}
          {transactionResp?.currency?.symbol} to{" "}
          {transactionResp?.receiver?.name}. Transaction Reference Id is #
          {transactionResp?.transactionId}
        </span>
        <div className="text-center text-sm text-grayColor dark:text-darkGrayColor block mt-2">
          <span>{getFormatedDate(transactionResp?.createdAt, true)}</span>
        </div>
        <Link to="/transactions">
          <button className="w-[100%] mt-6 hover:bg-btnHoverColor">
            View Details
          </button>
        </Link>
        <Link to="/">
          <button className="bg-transparent text-textColor w-full mt-2">
            Go Home
          </button>
        </Link>
      </div>
    </div>
  );
}
