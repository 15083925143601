import BigNumber from "bignumber.js";
import React, { useState } from "react";
import { BiLockAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { successAnim } from "../../../assets/gif";
import axios from "../../../axios";
import {
  BtnLoader,
  ConfirmPinModal,
  PayableCurrencySelect,
  PaymentConfirmSection,
} from "../../../components";
import { useBalance, usePrice } from "../../../hooks";
import { updateBalance } from "../../../redux/slices/currenciesSlice";
import { updateRecentActivities } from "../../../redux/slices/userSlice";
import { getFormatedDate } from "../../../utils";
import usePaymentPageAmount from "../hooks/usePaymentPageAmount";
import SinglePaymentPageOthers from "./SinglePaymentPageOthers";

export default function SinglePaymentPageForm({ paymentPage }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(false);
  const [transactionError, setTransactionError] = useState("");
  const [note, setNote] = useState("");
  const [section, setSection] = useState(1);
  const [transactionResp, setTransactionResp] = useState({});
  const [isConfirmPinModalOpen, setIsConfirmPinModalOpen] = useState(false);
  const [nonFixedAmount, setNonFixedAmount] = useState("");

  const { jwtToken, user, currentAccount, isLoggedIn } = useSelector(
    (state) => state.user
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const price = usePrice(selectedCurrency);
  const balance = useBalance(selectedCurrency?._id);
  const amount = usePaymentPageAmount(paymentPage, selectedCurrency, price);

  const handleNextClick = () => {
    if (!isLoggedIn) {
      navigate(`/auth/login?redirect=/page/${id}`);
      return;
    }

    setSection(2);
  };

  const handleProceed = () => {
    if (!isLoggedIn) {
      navigate(`/auth/login?redirect=/page/${id}`);
      return;
    }

    setIsConfirmPinModalOpen(true);
  };

  const handleSubmit = async (pinNumber) => {
    try {
      if (!isLoggedIn) {
        navigate(`/auth/login?redirect=/page/${id}`);
        return;
      }

      setIsLoading(true);
      setTransactionError("");
      const response = await axios.post(
        `/payments/page/${id}/hamipay`,
        {
          currencyId: selectedCurrency._id,
          senderAccountId: currentAccount?._id,
          note,
          amount: paymentPage?.amountType === "fixed" ? amount : nonFixedAmount,
          pinNumber,
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      setTransactionResp(response.data);
      setIsLoading(false);
      setSection(3);

      dispatch(updateRecentActivities(response.data));
      dispatch(
        updateBalance({
          ...response?.data,
          isSender: user._id === response.data?.sender?._id,
        })
      );

      if (paymentPage?.redirectionUrl) {
        window.open(paymentPage?.redirectionUrl);
      }
    } catch (err) {
      setTransactionError(
        err?.response?.data?.error || "Something went wrong, Try again"
      );
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <div className="px-5 md:px-0">
      <div className={section === 1 ? "block" : "hidden"}>
        <div>
          <div>
            <PayableCurrencySelect
              currencies={paymentPage?.acceptedCurrencies}
              selectedCurrency={selectedCurrency}
              setSelectedCurrency={setSelectedCurrency}
            />
          </div>
          <div className="mt-4">
            <div className="">
              <label htmlFor="">Amount</label>
              <input
                type="number"
                className={
                  "arrow-hidden font-medium h-[45px] " +
                  ((paymentPage?.amountType === "fixed" &&
                    selectedCurrency?._id &&
                    (!amount || Number(balance) < amount)) ||
                  (paymentPage?.amountType !== "fixed" &&
                    nonFixedAmount &&
                    Number(balance) < Number(nonFixedAmount))
                    ? "border-red-500"
                    : "")
                }
                placeholder="0.00"
                value={
                  paymentPage?.amountType === "fixed" ? amount : nonFixedAmount
                }
                disabled={paymentPage?.amountType === "fixed"}
                onChange={(e) => setNonFixedAmount(e.target.value)}
              />
            </div>
            <div className="flex items-start gap-[10px] justify-between mt-[5px]">
              <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
                Slippage tolerance is applied
              </span>
              <span
                className="block text-sm text-primaryColor font-medium cursor-pointer"
                onClick={() => {
                  let fee = BigNumber(balance).multipliedBy(0.5).dividedBy(100);
                  setNonFixedAmount(BigNumber(balance).minus(fee).toString());
                }}
              >
                Max
              </span>
            </div>
          </div>
          <div className="mt-4">
            <label htmlFor="">Note (optional)</label>
            <input
              type="text"
              placeholder="Enter a Note"
              className=""
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className={section === 2 ? "block" : "hidden"}>
        <PaymentConfirmSection
          setSection={setSection}
          amount={paymentPage?.amountType === "fixed" ? amount : nonFixedAmount}
          balance={balance}
          selectedCurrency={selectedCurrency}
          receiver={paymentPage?.creator}
        />
      </div>

      {(section === 1 || section === 2) && (
        <>
          <div className="mt-5">
            {transactionError && (
              <span className="block mb-3 text-[13px] text-red-500 font-medium">
                {transactionError}
              </span>
            )}
            <span className="block text-center text-grayColor dark:text-darkGrayColor text-[12px] mb-2">
              This payment is secured by LightningPay.
            </span>
            {section === 1 ? (
              <button
                className="w-[100%] hover:bg-btnHoverColor p-0 flex items-center justify-center gap-[10px] text-[14px] font-[500] uppercase disabled:cursor-not-allowed disabled:opacity-80"
                onClick={handleNextClick}
                disabled={
                  isLoggedIn &&
                  (Object.keys(selectedCurrency).length < 1 ||
                    (paymentPage?.amountType !== "fixed" &&
                      (!nonFixedAmount ||
                        Number(balance) < Number(nonFixedAmount))) ||
                    !balance ||
                    (paymentPage?.amountType === "fixed" &&
                      Number(balance) < amount))
                }
              >
                <>
                  <BiLockAlt />
                  Next
                </>
              </button>
            ) : (
              <button
                className="w-[100%] hover:bg-btnHoverColor p-0 flex items-center justify-center gap-[10px] text-[14px] font-[500] uppercase disabled:cursor-not-allowed disabled:opacity-80"
                onClick={handleProceed}
                disabled={
                  isLoading ||
                  Number(balance) <
                    BigNumber(
                      paymentPage?.amountType === "fixed"
                        ? amount
                        : nonFixedAmount
                    )
                      .multipliedBy(0.5)
                      .dividedBy(100)
                      .plus(
                        paymentPage?.amountType === "fixed"
                          ? amount
                          : nonFixedAmount
                      )
                      .toNumber()
                }
              >
                {isLoading ? (
                  <BtnLoader />
                ) : (
                  <>
                    <BiLockAlt />
                    Proceed
                  </>
                )}
              </button>
            )}

            {isConfirmPinModalOpen && (
              <ConfirmPinModal
                setIsConfirmPinModalOpen={setIsConfirmPinModalOpen}
                handleSubmit={handleSubmit}
              />
            )}

            <button
              type="button"
              className="w-[100%] bg-green-500 mt-4 p-0 flex items-center justify-center gap-[10px] text-[14px] font-[500]"
            >
              Pay with Lightning Wallet
            </button>
          </div>

          <div className="mt-7">
            <SinglePaymentPageOthers />
          </div>
        </>
      )}

      <div className={section === 3 ? "block" : "hidden"}>
        <div className="w-[140px] h-[140px] mx-auto">
          <img src={successAnim} alt="" className="w-[100%] h-[100%]" />
        </div>
        <h3 className="font-[600] text-xl text-center mt-3">Success !</h3>
        <span className="text-sm text-grayColor dark:text-darkGrayColor leading-[28px] block mt-2 text-center">
          You have successfully transfered {transactionResp?.amount}{" "}
          {transactionResp?.currency?.symbol} to{" "}
          {transactionResp?.receiver?.name}. Transaction Reference Id is #
          {transactionResp?.transactionId}
        </span>
        <div className="text-center text-sm text-grayColor dark:text-darkGrayColor block mt-2">
          <span>{getFormatedDate(transactionResp?.createdAt, true)}</span>
        </div>
        <button
          className="w-[100%] mt-6 hover:bg-btnHoverColor"
          onClick={() => setSection(1)}
        >
          Pay Again
        </button>
        <Link to="/transactions">
          <button className="bg-transparent text-textColor dark:text-darkTextColor w-full mt-2">
            View Details
          </button>
        </Link>
      </div>
    </div>
  );
}
