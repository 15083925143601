import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateTheme } from "../../redux/slices/layoutSlice";
import { AdminMenus } from "../../data/AdminMenus";
import { Link } from "react-router-dom";
import { FiChevronDown, FiGlobe } from "react-icons/fi";
// import { IoNotificationsOutline } from "react-icons/io5";
import { HiOutlineSun, HiMoon, HiMenuAlt1 } from "react-icons/hi";
// import { MdOutlineAccountBalance } from "react-icons/md";
import AdminDropdownMenu from "./AdminDropdownMenu";
import AdminProfileDropDown from "./AdminProfileDropDown";
import { ThunderLogoImg } from "../../assets/images";

function AdminHeader() {
  const { isLoggedIn, admin } = useSelector((state) => state.admin);
  const { theme } = useSelector((state) => state.layout);
  const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);
  const dispatch = useDispatch();
  const [isDropdownAcc, setIsDropdownAcc] = useState();
  const handleThemeChange = () => {
    localStorage.setItem("theme", theme === "light" ? "dark" : "light");
    dispatch(updateTheme(theme === "light" ? "dark" : "light"));
  };

  return (
    <div>
      <div className="border-b border-gray-200 h-[4.2em] sm:h-[5em] dark:border-darkBorderColor">
        <div className="max-w-[1240px] mx-auto h-[100%] px-5 flex items-center justify-between">
          <div className="flex items-center gap-12">
            <div className="flex items-center gap-4">
              <button
                className="bg-transparent h-auto text-textColor dark:text-darkTextColor p-0 text-[24px] sm:hidden"
                onClick={() => {
                  //   dispatch(setIsMobileSidebarOpen(true));
                }}
              >
                <HiMenuAlt1 />
              </button>
              <Link to="/">
                <div className="flex items-center gap-[13px]">
                  <img src={ThunderLogoImg} alt="logo" className="w-[40px]" />
                  {!isLoggedIn && (
                    <h1 className="font-bold text-[20px]">
                      Lightning
                      <span className="text-primaryColor">PAY</span>
                    </h1>
                  )}
                </div>
              </Link>
            </div>
            {isLoggedIn && (
              <ul className="hidden lg:flex items-center gap-7">
                {AdminMenus.map(({ name, link }, index) => {
                  // Conditionally render the "Admins" menu item only if admin.role is "super-admin"
                  if (name === "Admins" && admin.role !== "super-admin") {
                    return null; // Skip rendering this item if role is not "super-admin"
                  }

                  return (
                    <li key={index} className="text-sm font-medium">
                      <Link to={link}>{name}</Link>
                    </li>
                  );
                })}

                <li className="relative">
                  <span
                    className="flex items-center gap-[10px] cursor-pointer text-sm font-medium"
                    onClick={() => {
                      setIsDropdownMenuOpen(true);
                    }}
                  >
                    More <FiChevronDown />
                  </span>

                  {isDropdownMenuOpen && (
                    <>
                      <div
                        className="fixed top-0 left-0 w-[100%] h-[100%] z-10"
                        onClick={() => setIsDropdownMenuOpen(false)}
                      ></div>
                      <AdminDropdownMenu
                        setIsDropdownMenuOpen={setIsDropdownMenuOpen}
                      />
                    </>
                  )}
                </li>
              </ul>
            )}
          </div>
          <div className="flex items-center gap-[0.7em]">
            <div className="hidden sm:flex relative items-center gap-[10px] text-sm font-medium mr-[10px]">
              <span className="flex items-center gap-[8px]">
                <FiGlobe /> EN
              </span>{" "}
              <FiChevronDown />
            </div>
            <div className="relative hidden sm:block">
              <button
                className="cursor-pointer relative p-0 bg-transparent text-textColor dark:text-darkTextColor text-[18px] w-[36px] h-[36px] flex items-center justify-center rounded-full hover:bg-[#eaf1fe] hover:text-blue-500 dark:hover:bg-[#32394e]"
                onClick={handleThemeChange}
              >
                {theme === "dark" ? <HiOutlineSun /> : <HiMoon />}
              </button>
            </div>

            {isLoggedIn && (
              <div className="hidden sm:block relative ml-[0.9em]">
                <div className="flex gap-3 cursor-pointer">
                  <div className="w-[35px] h-[35px] cursor-pointer rounded overflow-hidden">
                    <img
                      src={
                        "https://static.vecteezy.com/system/resources/previews/009/398/577/non_2x/man-avatar-clipart-illustration-free-png.png"
                      }
                      className="w-[100%] h-[100%] object-cover"
                      alt=""
                    />
                  </div>
                  {isDropdownAcc && (
                    <AdminProfileDropDown setIsDropdownAcc={setIsDropdownAcc} />
                  )}
                  <div
                    onClick={() => {
                      setIsDropdownAcc(true);
                    }}
                  >
                    <h1>Welcome</h1>
                    <h1 className="text-xs">{admin.email}</h1>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminHeader;
