import React, { useEffect, useState } from "react";
// import { FiChevronDown } from "react-icons/fi";
import { IoNotificationsOutline } from "react-icons/io5";
import { HiOutlineSun, HiMoon, HiMenuAlt1 } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineAccountBalance, MdOutlineAccountBalanceWallet } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import {  ThunderLogoImg } from "../../assets/images";
import UserDropdown from "./UserDropdown";
import NotificationDropdown from "./NotificationDropdown";
import {
  setIsMobileSidebarOpen,
  updateTheme,
} from "../../redux/slices/layoutSlice";
import { menus } from "../../data";
import AccountDropdown from "./AccountDropdown";
import axios from "../../axios";
import { setNotifications } from "../../redux/slices/userSlice";
// import DropdownMenu from "./DropdownMenu";
import CreateNewAccountModal from "./CreateNewAccountModal";
import EmailVerifyModal from "./EmailVerifyModal";
import MobileSidebar from "./MobileSidebar";
import ErrorAndReloadModal from "../ErrorAndReloadModal";
import { useCallback } from "react";

export default function Header() {
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] =
    useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [isAccountDropdownOpen, setIsAccountDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [isDropdownMenuOpen, setIsDropdownMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    theme,
    isCreateAccountModalOpen,
    isEmailVerifyModalOpen,
    isMobileSidebarOpen,
    isErrorAndReloadModalOpen,
  } = useSelector((state) => state.layout);
  const { user, jwtToken, isLoggedIn, totalUnReadNotifications } = useSelector(
    (state) => state.user
  );

  const handleThemeChange = () => {
    localStorage.setItem("theme", theme === "light" ? "dark" : "light");
    dispatch(updateTheme(theme === "light" ? "dark" : "light"));
  };

  const getHeaderData = useCallback(async () => {
    try {
      const response = await axios.get("/home/header/all", {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });
      console.log(response.data);
      dispatch(setNotifications(response?.data));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }, [dispatch, jwtToken]);

  useEffect(() => {
    if (isLoggedIn) {
      getHeaderData();
    }
  }, [isLoggedIn, getHeaderData]);

  return (
    <div className="sticky top-0 bg-white dark:bg-darkBgColor z-10">
      {/* <div className="border-b h-[40px] w-full">
                <div className="max-w-[1240px] mx-auto h-[100%] flex items-center justify-between">
                    <div className="flex items-center gap-[10px]">
                        <div className="flex items-center">
                            <span>BTC</span>
                            <span>$24,054</span>
                        </div>
                        <div className="flex items-center">
                            <span>ETH</span>
                            <span>$24,054</span>
                        </div>
                        <div className="flex items-center">
                            <span>LIGHTNING</span>
                            <span>$24,054</span>
                        </div>
                    </div>
                    <div className="flex items-center gap-[0.7em]">
                        <div className="relative flex items-center gap-[10px] text-sm font-medium mr-[10px]">
                            <span className="flex items-center gap-[8px]">
                                <FiGlobe /> EN
                            </span>{" "}
                            <FiChevronDown />
                        </div>
                        <div className="relative">
                            <button
                                className="cursor-pointer relative p-0 bg-transparent text-textColor dark:text-darkTextColor text-[18px] w-[36px] h-[36px] flex items-center justify-center rounded-full hover:bg-[#eaf1fe] hover:text-blue-500 dark:hover:bg-[#32394e]"
                                onClick={handleThemeChange}
                            >
                                {theme === "dark" ? (
                                    <HiOutlineSun />
                                ) : (
                                    <HiMoon />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}
      <div className="border-b border-gray-200 h-[4.2em] sm:h-[5em] dark:border-darkBorderColor">
        <div className="max-w-[1240px] mx-auto h-[100%] px-5 flex items-center justify-between">
          <div className="flex items-center gap-12">
            <div className="flex items-center gap-4">
              {isLoggedIn ? (<> <button
                className="bg-transparent h-auto text-textColor dark:text-darkTextColor p-0 text-[24px] sm:hidden"
                onClick={() => {
                  dispatch(setIsMobileSidebarOpen(true));
                }}
              >
                <HiMenuAlt1 />
              </button>
              </>):(<>
                </>)}
             
              <Link to="/">
                <div className="flex items-center gap-[13px]">
                <img src={ThunderLogoImg} alt="logo" className="w-[40px]" />
                  {(!isLoggedIn ||
                    !user?.isEmailVerified ||
                    !user?.isMobileNumberVerified) && (
                    <h1 className="font-bold text-[20px]">
                      Lightning
                  
                    </h1>
                  )}
                </div>
              </Link>
            </div>
            {isLoggedIn && user?.isMobileNumberVerified && (
              <ul className="hidden lg:flex items-center gap-7 ">
                {menus.map(({ name, link }, index) => {
                  return (
                    <li key={index} className="text-sm font-medium">
                      <Link to={link}>{name}</Link>
                    </li>
                  );
                })}
                {/* <li className="relative">
                  <span
                    className="flex items-center gap-[10px] cursor-pointer text-sm font-medium"
                    onClick={() => {
                      setIsDropdownMenuOpen(true);
                    }}
                  >
                    More <FiChevronDown />
                  </span>

                  {isDropdownMenuOpen && (
                    <>
                      <div
                        className="fixed top-0 left-0 w-[100%] h-[100%] z-10"
                        onClick={() => setIsDropdownMenuOpen(false)}
                      ></div>
                      <DropdownMenu
                        setIsDropdownMenuOpen={setIsDropdownMenuOpen}
                      />
                    </>
                  )}
                </li> */}
              </ul>
            )}
          </div>
          <div className="flex items-center gap-[0.7em]">
            {/* <div className="hidden sm:flex relative items-center gap-[10px] text-sm font-medium mr-[10px]">
              <span className="flex items-center gap-[8px]">
                <FiGlobe /> EN
              </span>{" "}
              <FiChevronDown />
            </div> */}
            {/* <div className="hidden sm:flex relative items-center gap-[10px] text-sm font-medium mr-[10px]">
              <span className="flex items-center gap-[8px] capitalize ">
                <span
                  className={`text-xs${
                    user?.type === "merchant" && " text-red-600  "
                  } ${user?.type === "personal" && "text-green-600 "}`}
                >
                  {user?.type} 
                </span>
              </span>{" "}
            </div> */}
            <div className="relative hidden sm:block">
              <button
                className="cursor-pointer relative p-0 bg-transparent text-textColor dark:text-darkTextColor text-[18px] w-[36px] h-[36px] flex items-center justify-center rounded-full hover:bg-[#eaf1fe] hover:text-blue-500 dark:hover:bg-[#32394e]"
                onClick={handleThemeChange}
              >
                {theme === "dark" ? <HiOutlineSun /> : <HiMoon />}
              </button>
            </div>
            {isLoggedIn && user?.isMobileNumberVerified && (
              <>
                <div className="hidden sm:block relative">
                  <div
                    className="cursor-pointer relative text-[18px] w-[36px] h-[36px] flex items-center justify-center rounded-full hover:bg-[#eaf1fe] hover:text-blue-500 dark:hover:bg-[#32394e]"
                    onClick={() =>
                      setIsAccountDropdownOpen(!isAccountDropdownOpen)
                    }
                  >
                    <MdOutlineAccountBalanceWallet />
                  </div>
                  {isAccountDropdownOpen && (
                    <AccountDropdown
                      isLoading={isLoading}
                      setIsAccountDropdownOpen={setIsAccountDropdownOpen}
                    />
                  )}
                </div>
                <div className="relative">
                  <div
                    className="hidden sm:flex cursor-pointer relative text-[18px] w-[36px] h-[36px] items-center justify-center rounded-full hover:bg-[#eaf1fe] hover:text-blue-500 dark:hover:bg-[#32394e]"
                    onClick={() =>
                      setIsNotificationDropdownOpen(!isNotificationDropdownOpen)
                    }
                  >
                    <IoNotificationsOutline />
                    <div className="absolute top-[-1px] right-[4px] rounded-full text-[10px] font-medium dark:text-black text-[#fff] w-[17px] h-[17px] bg-primaryColor flex items-center justify-center">
                      {totalUnReadNotifications || 0}
                    </div>
                  </div>
                  <div
                    className="flex sm:hidden cursor-pointer relative text-[18px] w-[36px] h-[36px]  items-center justify-center rounded-full hover:bg-[#eaf1fe] hover:text-blue-500 dark:hover:bg-[#32394e]"
                    onClick={() => navigate("/notifications")}
                  >
                    <IoNotificationsOutline />
                    <div className="absolute top-[-1px] right-[4px] rounded-full text-[10px] font-medium dark:text-black text-[#fff] w-[17px] h-[17px] bg-primaryColor flex items-center justify-center">
                      {totalUnReadNotifications || 0}
                    </div>
                  </div>
                  {isNotificationDropdownOpen && (
                    <NotificationDropdown
                      setIsNotificationDropdownOpen={
                        setIsNotificationDropdownOpen
                      }
                    />
                  )}
                </div>
              </>
            )}
            {isLoggedIn && (
              <div className="hidden sm:block relative ml-[0.9em]">
                <div
                  className="w-[35px] h-[35px] cursor-pointer rounded overflow-hidden"
                  onClick={() => {
                    setIsUserDropdownOpen(!isUserDropdownOpen);
                  }}
                >
                  <img
                    src="https://avatar.iran.liara.run/public"
                    className="w-[100%] h-[100%] object-cover"
                    alt=""
                  />
                </div>
                {isUserDropdownOpen && (
                  <UserDropdown setIsUserDropdownOpen={setIsUserDropdownOpen} />
                )}
              </div>
            )}
            {!isLoggedIn && (
              <>
                <Link to="/auth/login">
                  <button className="h-[35px] bg-transparent border dark:border-darkBorderColor text-textColor dark:text-darkTextColor hover:bg-btnHoverColor hover:text-[#fff] hover:dark:text-[#fff]">
                    Login
                  </button>
                </Link>
                <Link to="/auth/register" className="hidden lg:block">
                  <button className="h-[35px] hover:bg-btnHoverColor">
                    Create Account
                  </button>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
      {/* <div className="border-b bg-slate-00">
                <div className="max-w-[1200px] mx-auto flex items-center justify-between">
                    <ul className="flex items-center gap-7 text-[15px] ">
                        <li>Home</li>
                        <li>Wallet</li>
                        <li>Activities</li>
                        <li>Help</li>
                        <li>Payments</li>
                    </ul>
                    <span>Developers</span>
                </div>
            </div> */}

      {/* MESSAGE ICON */}
      {/* <div className="fixed bottom-7 right-7 z-20 ">
                <button className="w-[50px] h-[50px] text-xl p-0 flex items-center justify-center hover:bg-blueHoverColor rounded-full">
                    <BsFillChatSquareFill />
                </button>
            </div> */}

      {isCreateAccountModalOpen && <CreateNewAccountModal />}
      {isEmailVerifyModalOpen && <EmailVerifyModal />}
      {isMobileSidebarOpen && <MobileSidebar />}
      {isErrorAndReloadModalOpen && <ErrorAndReloadModal />}
    </div>
  );
}
