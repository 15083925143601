import React, { useEffect, useState } from "react";
import { BiMailSend } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../axios";
import { logoutUser } from "../../redux/slices/userSlice";

export default function VerifyEmailPage() {
  const [timer, setTimer] = useState(0);
  const [timerActive, setTimerActive] = useState(false);

  const { user, jwtToken } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const resendEmailHandler = async () => {
    try {
      setTimerActive(true);
      setTimer(45);

      await axios.post(
        "/users/resend-email",
        {},
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let interval = null;
    if (timerActive) {
      interval = window.setInterval(() => {
        if (timer <= 1) {
          setTimerActive(false);
          window.clearInterval(interval);
        }
        setTimer((prev) => {
          return prev - 1;
        });
      }, 1000);
    }

    return () => window.clearInterval(interval);
  }, [timerActive, timer]);

  return (
    <div className="flex items-center justify-center min-h-[calc(var(--doc-height)-4.2em)] sm:min-h-[calc(100vh-5em)] px-5 py-6">
      <div className="w-[100%] max-w-[450px]">
        <div className="relative">
          <div className="text-center">
            <div className="w-[72px] h-[72px] rounded-full flex items-center mx-auto justify-center bg-[#eff3f7] dark:bg-darkCardColor text-primaryColor text-3xl">
              <BiMailSend />
            </div>
            <h3 className="font-bold text-lg sm:text-xl mt-4 ">
              Verify your email
            </h3>
            <span className="text-[14px] block text-grayColor dark:text-darkGrayColor mt-3 leading-[28px]">
              We have sent you a verification email to{" "}
              <span className="font-[600] text-textColor dark:text-darkTextColor">
                {user?.email}
              </span>
              , Please check your email and click the verify link to activate
              LightningPay account.{" "}
              <span
                className="font-[400] text-blueColor underline cursor-pointer"
                onClick={() => {
                  dispatch(logoutUser());
                }}
              >
                logout
              </span>
            </span>

            <a href="https://mail.google.com/" target="blank">
              <button className="mt-5 h-[40px] bg-greenColor hover:bg-greenHoverColor">
                Verify Email
              </button>
            </a>
          </div>
        </div>
        <div className="text-center mt-5">
          <span className="block text-[13px] mb-[6px]">
            Didn't receive a code ?{" "}
            {timer > 0 ? (
              <span className="text-primaryColor font-medium cursor-not-allowed">
                Resend in {timer} s
              </span>
            ) : (
              <span
                className="text-primaryColor font-medium cursor-pointer"
                onClick={resendEmailHandler}
              >
                Resend
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  );
}
