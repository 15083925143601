import React from "react";
import { Link } from "react-router-dom";
import { setIsMobileSidebarOpen } from "../../redux/slices/layoutSlice";
import { useDispatch } from "react-redux";

export default function MobileSidbarSingleMenu({ menu }) {

    const dispatch = useDispatch();

    return (
        <li className="">
            <Link
                to={menu.link}
                className="flex items-center gap-[15px] py-3 border-b pr-5 pl-5"
                onClick={() => dispatch(setIsMobileSidebarOpen(false))}
            >
               
                
                <div>
                    <span className="block font-medium">{menu.name}</span>
                  
                </div>
          
            </Link>

           
        </li>
    );
}
