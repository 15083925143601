import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch} from "react-redux";

import { BtnLoader } from "../../components";
import { validateEmail } from "../../utils";
import { setUser } from "../../redux/slices/userSlice";
import axios from "../../axios";

export default function RegisterPage() {
  const [data, setData] = useState({
    username: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: false,
    email: false,
    password: false,
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isUsernameValid = (username) => {
    if (username) {
      const regx = /^(?=.{4,15}$)[a-zA-Z0-9_]+$/gm;
      if (!regx.test(username?.trim())) {
        setErrors((prev) => {
          return {
            ...prev,
            username: true,
          };
        });
      } else {
        setErrors((prev) => {
          return {
            ...prev,
            username: false,
          };
        });
      }
    }
  };

  const isEmailValid = (email) => {
    if (email) {
      if (!validateEmail(email)) {
        setErrors((prev) => {
          return {
            ...prev,
            email: true,
          };
        });
      } else {
        setErrors((prev) => {
          return {
            ...prev,
            email: false,
          };
        });
      }
    }
  };

  const isPasswordValid = (password) => {
    if (password) {
      if (password.length < 8) {
        setErrors((prev) => {
          return {
            ...prev,
            password: true,
          };
        });
        return false;
      } else {
        setErrors((prev) => {
          return {
            ...prev,
            password: false,
          };
        });
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "username") {
      isUsernameValid(e.target.value);
    }
    if (e.target.name === "email") {
      isEmailValid(e.target.value);
    }
    if (e.target.name === "password") {
      isPasswordValid(e.target.value);
    }

    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setError("");

      setIsLoading(true);

    const response =  await axios.post("/users/register", {
        ...data,
      });

      dispatch(setUser(response.data));
      setIsLoading(false);

      navigate("/auth/verify-email");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setError(err?.response?.data?.error || "Something went wrong, Try again");
    }
  };

  

  return (
    <div className="relative flex flex-col items-center py-6 justify-center min-h-[calc(var(--doc-height)-4.2em)] sm:min-h-[calc(100vh-5em)] max-w-[450px] mx-auto px-5">
      <h1 className="font-bold text-lg sm:text-xl mb-7">Register</h1>

      <form className="w-full" onSubmit={handleSubmit}>
      <div className="mt-4">
          <label htmlFor="username">Username *</label>
          <input
            type="text"
            id="username"
            placeholder="Enter username"
            name="username"
            onChange={handleChange}
            className={" " + (errors.username ? "border-red-500" : "")}
          />
        </div>
        <div className="mt-4">
          <label htmlFor="email">Email *</label>
          <input
            type="email"
            id="email"
            placeholder="Enter email"
            name="email"
            onChange={handleChange}
            className={" " + (errors.email ? "border-red-500" : "")}
          />
        </div>
        <div className="mt-4">
          <label htmlFor="password">Password *</label>
          <input
            type="password"
            id="password"
            placeholder="Enter password"
            name="password"
            onChange={handleChange}
            className={" " + (errors.password ? "border-red-500" : "")}
          />
        </div>
        <div className="flex mt-4 items-center gap-[7px]">
          <input
            type="checkbox"
            name=""
            id="remember-me"
            className="cursor-pointer w-[14px] h-[14px]"
          />
          <label htmlFor="remember-me" className="mb-0">
            Remember me
          </label>
        </div>
        <div className="mt-4">
          {error && (
            <span className="text-[13px] mb-4 font-medium block text-red-600">
              {error}
            </span>
          )}
          <button
            className="w-[100%] hover:bg-btnHoverColor py-0 disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor"
            disabled={
              isLoading ||
           
              !data.email ||
              !data.password ||
              errors.password === true ||
              errors.email === true 
            }
          >
            {isLoading ? <BtnLoader /> : "Register"}
          </button>
        </div>
      </form>

      <div className="px-5 mt-6">
        <span className="text-[13px] text-grayColor dark:text-darkGrayColor gap-[5px]  ">
          By registering you agree to the
          <span className="font-medium text-primaryColor cursor-pointer">
            Terms of Use
          </span>
        </span>
      </div>
      <div className="text-center mt-5">
        <span className="text-[13px]">
          Already have an account ?{" "}
          <Link to="/auth/login" className="text-primaryColor font-medium">
            Login
          </Link>
        </span>
      </div>
    </div>
  );
}
