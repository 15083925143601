import moment from "moment/moment";
import React, { useRef } from "react";
import { BiTimeFive } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { avatarImg } from "../../assets/images";
import axios from "../../axios";
import { useHandleClickOutside } from "../../hooks";
import {
  setAllNotificationAsRead,
  setNotificationAsRead,
} from "../../redux/slices/userSlice";

export default function NotificationDropdown({
  setIsNotificationDropdownOpen,
}) {
  const { notifications, user, jwtToken, totalUnReadNotifications } =
    useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const wrapperRef = useRef();

  useHandleClickOutside(wrapperRef, () => setIsNotificationDropdownOpen(false));

  const updateNotificationAsRead = async (notification) => {
    try {
      if (
        (notification?.isGlobalNotification === true &&
          !notification?.openedUsers?.includes(user?._id)) ||
        (notification?.isGlobalNotification === false &&
          notification.isOpened === false)
      ) {
        const response = await axios.patch(
          `/notifications/read/${notification?._id}`,
          {},
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        dispatch(
          setNotificationAsRead({
            notificationId: notification._id,
            userId: user._id,
            name: "notifications",
          })
        );
      }

      if (notification?.url) {
        setIsNotificationDropdownOpen(false);
        if (notification.urlType === "relative") {
          navigate(notification?.url);
        } else {
          window.open(notification?.url);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateAllAsRead = async () => {
    try {
      if (notifications.length > 0) {
        await axios.patch(
          "/notifications/all/read",
          {},
          {
            headers: { Authorization: `Bearer ${jwtToken}` },
          }
        );
        dispatch(
          setAllNotificationAsRead({
            userId: user?._id,
            name: "notifications",
          })
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      ref={wrapperRef}
      className="hidden sm:block bg-white dark:bg-darkCardColor rounded fixed inset-0 sm:absolute sm:right-0 sm:top-[54px] sm:left-auto sm:bottom-auto min-w-[325px] shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] z-10"
    >
      <div className="p-4 flex items-center justify-between gap-[10px]">
        <h5 className="text-base sm:text-[13px] font-[600] sm:font-[500]">
          Notifications
        </h5>
        <button
          className="text-[10px] text-blueColor hidden sm:block bg-transparent h-auto p-0 font-medium disabled:cursor-not-allowed"
          onClick={updateAllAsRead}
          disabled={notifications?.length < 1 || totalUnReadNotifications <= 0}
        >
          Mark all as read
        </button>
        <span
          className="sm:hidden text-2xl"
          onClick={() => {
            setIsNotificationDropdownOpen(false);
          }}
        >
          <IoMdClose />
        </span>
      </div>

      <div>
        {notifications?.length < 1 ? (
          <div className="">
            <span className="px-4 pb-8 pt-4 text-center block text-sm text-grayColor dark:text-darkGrayColor leading-[26px]">
              No notifications yet
              <br />
              Messages and alerts about your account will show up
              here.
            </span>
          </div>
        ) : (
          notifications?.slice(0, 3).map((notification, index) => {
            return (
              <div
                key={index}
                className={
                  "relative hover:bg-[#f6f6f6] dark:hover:bg-darkCardHoverColor px-4 py-3 flex items-start gap-4 transition-all cursor-pointer mb-[2px] " +
                  ((
                    notification?.isGlobalNotification
                      ? notification?.openedUsers?.includes(user?._id)
                      : notification?.isOpened
                  )
                    ? ""
                    : "dark:bg-gray-700 bg-gray-300")
                }
                onClick={() => {
                  updateNotificationAsRead(notification);
                }}
              >
                <div className="w-[32px] h-[32px] min-w-[32px] min-h-[32px] rounded-full overflow-hidden">
                  {/* <img
                    src={
                      notification?.notificationType === "transaction" ||
                      notification.notificationType === "payment-request"
                        ? notification?.relatedUser?.avatar
                          ? process.env.REACT_APP_SERVER_URL +
                            notification?.relatedUser?.avatar
                          : avatarImg
                        : "https://www.pngall.com/wp-content/uploads/2017/05/Alert.png"
                    }
                    alt=""
                    className="w-[100%] h-[100%] object-cover"
                  /> */}
                </div>
                <div>
                  <span className="block text-[13px] font-medium mb-[0.25rem]">
                    {notification?.title
                      ?.replace("{name}", notification?.relatedUser?.name)
                      ?.slice(0, 30)}
                    {notification?.title?.length > 30 && "..."}
                  </span>
                  <span className="block text-[12px] mb-[0.25rem] text-grayColor dark:text-darkGrayColor">
                    {notification?.description?.slice(0, 36)}
                    {notification?.description?.length > 36 && "..."}
                  </span>
                  <span className="text-grayColor text-[12px] flex items-center gap-[5px]">
                    <BiTimeFive />
                    {moment(notification?.createdAt).fromNow()}
                  </span>
                </div>
              </div>
            );
          })
        )}
      </div>

      {notifications?.length > 1 && (
        <Link
          to="/notifications"
          className="text-primaryColor block text-center text-[12px] pb-3 pt-1 cursor-pointer"
          onClick={() => setIsNotificationDropdownOpen(false)}
        >
          View All
        </Link>
      )}
    </div>
  );
}
