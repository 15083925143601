import React from "react";
import { AiTwotonePlusCircle } from "react-icons/ai";
import { BiHome, BiTimeFive,  BiUser } from "react-icons/bi";

import { IoWalletOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function BottomBar() {
    return (
        <div className="sm:hidden fixed bottom-0 left-0 w-[100%] h-[60px]  bg-primaryColor  shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
            <div className=" flex items-center justify-around h-full">
                <div>
                    <Link to="/">
                        <button className="text-[23px] h-auto p-0 bg-transparent text-[#fff] dark:text-black">
                            <BiHome />
                        </button>
                    </Link>
                </div>
                <div className="">
                    <Link to="/currencies">
                        <button className="text-[23px] h-auto p-0 bg-transparent text-[#fff] dark:text-black">
                            <IoWalletOutline />
                        </button>
                    </Link>
                </div>
                <div className="">
                    <Link to="/currencies">
                        <button className="text-[23px] h-auto p-0 bg-transparent text-[#fff] dark:text-black">
                        <AiTwotonePlusCircle />                        </button>
                    </Link>
                </div>
               
                <div>
                    <Link to="/transactions">
                        <button className="text-[23px] h-auto p-0 bg-transparent text-[#fff] dark:text-black">
                            <BiTimeFive />
                        </button>
                    </Link>
                </div>
                
                <div>
                    <Link to="/settings">
                        <button className="text-[23px] h-auto p-0 bg-transparent text-[#fff] dark:text-black">
                            <BiUser />
                        </button>
                    </Link>
                </div>

               
            </div>
        </div>
    );
}
