import React from "react";
import { FiChevronDown } from "react-icons/fi";

export default function SinglePaymentPageDetailsSkeleton() {
  return (
    <div className="flex flex-col">
      <div className="relative flex-1">
        <div className="block md:hidden sticky top-[4.2em] left-0 w-full">
          <div className="bg-[#f6f6f6] dark:bg-darkCardHoverColor p-5 flex items-center justify-between gap-[25px]">
            <div className=" flex-1">
              <span className="block h-[17px] w-full bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
              <span className="block h-[17px] w-[60%] bg-slate-300 dark:bg-slate-600 mt-2 animate-pulse"></span>
            </div>
            <div>
              <FiChevronDown />
            </div>
          </div>
        </div>

        <div className="hidden md:block absolute md:relative top-[100%] md:top-auto p-5 md:p-0 left-0 md:left-auto w-[100%] z-10 bg-[#fff] md:bg-transparent shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)] md:shadow-none ">
          <span className="block h-[20px] w-full bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
          <span className="block h-[20px] w-[50%] mt-2 bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
          <div className="mt-4">
            <span className="h-[14px] block w-full bg-slate-300 dark:bg-slate-600 animate-pulse"></span>
            <span className="h-[14px] block w-[40%] bg-slate-300 dark:bg-slate-600 animate-pulse mt-2"></span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Amount
            </span>
            <span className="block h-[24px] w-[70px] bg-slate-300 dark:bg-slate-600 animate-pulse mt-1"></span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Accepted Currencies
            </span>
            <div className="flex items-center gap-[10px] mt-[7px]">
              {Array.from({ length: 2 })?.map((_, index) => {
                return (
                  <div
                    key={index}
                    className="h-[25px] rounded px-[5px] w-[60px] bg-slate-300 dark:bg-slate-600 animate-pulse gap-[6px]"
                  >
                    <span className=""></span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Expiry Date
            </span>
            <span className="text-[15px] font-medium">
              <span className="h-[15px] w-[100px] block bg-slate-300 dark:bg-slate-600 animate-pulse mt-1"></span>
            </span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Contact Name
            </span>
            <span className="text-[15px] font-medium">
              <span className="h-[15px] w-[70px] bg-slate-300 dark:bg-slate-600 animate-pulse block mt-1"></span>
            </span>
          </div>
          <div className="mt-4">
            <span className="block text-[13px] text-grayColor dark:text-darkGrayColor">
              Contact Email
            </span>
            <span className="text-[15px] font-medium">
              <span className="h-[15px] w-[120px] bg-slate-300 dark:bg-slate-600 animate-pulse block mt-1"></span>
            </span>
          </div>
        </div>
      </div>

      <div className="hidden md:flex items-center gap-[10px] text-[12px]">
        <span>
          Powered by{" "}
          <a href="" target="blank" className="font-[600] text-sm">
            Lightning
            <span className="text-primaryColor">pay</span>
          </a>
        </span>
        <span className="text-grayColor">|</span>
        <span>Terms</span>
        <span>Privacy</span>
      </div>
    </div>
  );
}
