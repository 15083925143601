const menus = [
  {
    name: "Home",
    link: "/"
  },
  {
    name: "Projects",
    link: "/"
  },
  {
    name: "Market Making",
    link: "/"
  },
  {
    name: "Transactions",
    link: "/"
  },
  
  {
    name: "Settings",
    link: "/settings"
  }
  // {
  //     name: "Swap",
  //     link: "/swap",
  // },
  // {
  //     name: "Help",
  //     link: "/",
  // },
];

export default menus;
