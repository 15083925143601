import React from "react";
import { MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { menus } from "../../data";
import { setIsMobileSidebarOpen } from "../../redux/slices/layoutSlice";
import MobileSidbarSingleMenu from "./MobileSidbarSingleMenu";

export default function MobileSidebar() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <div className="fixed inset-0 bg-[#fff] dark:bg-darkBgColor flex flex-col">
      <div className="flex items-start justify-between gap-[10px] w-full dark:text-black h-[120px] bg-primaryColor p-5">
        <div className="flex items-start gap-[15px]">
          <div className="w-[45px] h-[45px] rounded-full overflow-hidden">
            <img
              src="https://avatar.iran.liara.run/public"
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <span className="block text-white dark:text-black text-lg font-medium">
              {user?.name}
            </span>
            <span className="block text-sm text-[#eee] dark:text-black mt-[3px]">
              +{user?.phonecode} {user?.mobileNumber}
            </span>
            <Link
              to="/settings/profile"
              className="text-blue-800 underline text-sm mt-[6px] block"
              onClick={() => dispatch(setIsMobileSidebarOpen(false))}
            >
              Edit Profile
            </Link>
          </div>
        </div>
        <span
          onClick={() => {
            dispatch(setIsMobileSidebarOpen(false));
          }}
          className="w-[25px] h-[25px]  rounded-full bg-white flex items-center justify-center text-xl"
        >
          <MdClose />
        </span>
      </div>

      <div className="flex-1">
        <ul>
          {menus.map((menu, index) => {
            return <MobileSidbarSingleMenu key={index} menu={menu} />;
          })}
        </ul>
      </div>

      <span className="block text-center p-5 text-sm text-grayColor">
        Lightning &copy; {new Date().getFullYear()}
      </span>
    </div>
  );
}
