import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import axios from "../../axios";
import { toasts } from "../../utils";

export default function PasswordSecurityPage() {
  const [data, setData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  const { jwtToken } = useSelector((state) => state.user);

  const isPasswordValid = (password) => {
    if (password) {
      if (password.length < 8) {
        setErrors((prev) => {
          return {
            ...prev,
            newPassword: true,
          };
        });
      } else {
        setErrors((prev) => {
          return {
            ...prev,
            newPassword: false,
          };
        });
      }
    }
  };

  const isConfirmPasswordValid = (password, cpassword) => {
    if (password && password !== cpassword) {
      setErrors((prev) => {
        return {
          ...prev,
          confirmPassword: true,
        };
      });
    } else {
      setErrors((prev) => {
        return {
          ...prev,
          confirmPassword: false,
        };
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "newPassword") {
      isPasswordValid(e.target.value);
      isConfirmPasswordValid(e.target.value, data.confirmPassword);
    }
    if (e.target.name === "confirmPassword") {
      isConfirmPasswordValid(data.newPassword, e.target.value);
    }
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      setError("");

      await axios.post(
        "/users/update-password",
        {
          ...data,
        },
        {
          headers: { Authorization: `Bearer ${jwtToken}` },
        }
      );

      toasts.showSuccess("Password updated successfully");

      setIsLoading(false);
      setData({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    } catch (err) {
      setError(err?.response?.data?.error || "Something went wrong, Try again");
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-[calc(100vh-5em)]">
      <div className="mb-8 max-w-[650px]">
        <h2 className="text-lg font-[600] mb-1">Change Password</h2>
        <span className="text-sm text-grayColor dark:text-darkGrayColor leading-[28px]">
          Enter your wallet public address and mnemonic. If there is no wallet
          in ethereum, bitcoin or solana, Lightning Pay will be created
          automatically for you.
        </span>
      </div>
      <form action="" onSubmit={handleSubmit}>
        <div className="md:flex gap-[20px]">
          <div className="md:w-[400px]">
            <label htmlFor="">Current Password</label>
          </div>
          <div className="w-full">
            <input
              type="password"
              placeholder="**********"
              name="currentPassword"
              value={data.currentPassword || ""}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="md:flex gap-[20px] mt-5">
          <div className="md:w-[400px]">
            <label htmlFor="">New Password</label>
          </div>
          <div className="w-full">
            <input
              type="password"
              placeholder="**********"
              name="newPassword"
              value={data.newPassword || ""}
              onChange={handleChange}
              className={errors.newPassword ? "border border-red-500" : ""}
            />
            <span className="text-[13px] block mt-[7px] text-grayColor dark:text-darkGrayColor leading-[22px]">
              Password should be atleast 8 letters long
            </span>
          </div>
        </div>
        <div className="md:flex gap-[20px] mt-5">
          <div className="md:w-[400px]">
            <label htmlFor="">Re-type New Password</label>
          </div>
          <div className="w-full">
            <input
              type="password"
              placeholder="**********"
              name="confirmPassword"
              value={data.confirmPassword || ""}
              onChange={handleChange}
            />
            {errors.confirmPassword && (
              <span className="block text-red text-sm mt-[5px] text-red-500">
                Password is not same!!
              </span>
            )}
          </div>
        </div>

        {error && (
          <div className="md:flex gap-[20px] mt-5">
            <div className="md:w-[400px]"></div>
            <span className="block w-full text-sm text-red-500 leading-[28px]">
              {error}
            </span>
          </div>
        )}

        <div className="mt-10">
          <button
            className="block ml-auto hover:bg-btnHoverColor disabled:opacity-80"
            disabled={
              isLoading ||
              !data.currentPassword ||
              !data.newPassword ||
              !data.confirmPassword ||
              data.newPassword !== data.confirmPassword ||
              data.newPassword?.length < 8
            }
          >
            Update Password
          </button>
        </div>
      </form>
    </div>
  );
}
