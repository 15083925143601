import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import { BottomBar,  Header } from "../components";
import {
    fetchHomeData,
    updateIsHomeDataFetching,
} from "../redux/slices/userSlice";

export default function MainLayout() {
    const { accountId } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updateIsHomeDataFetching(true));
        dispatch(fetchHomeData());
    }, [accountId, dispatch]);

    return (
        <div>
            <Header />
            <main className="w-[100%] max-w-[1240px] px-5 mx-auto pb-[4.5em] sm:pb-0">
                <Suspense fallback={<div>Loading...</div>}>
                    <Outlet />
                </Suspense>
            </main>
            <BottomBar />
            {/* <Footer /> */}
        </div>
    );
}
