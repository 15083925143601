import React from "react";

export default function SinglePaymentPageFormSkeleton() {
  return (
    <div className="px-5 md:px-0">
      <div>
        <span className="block mb-[0.5rem] h-[14px] w-[70px] bg-slate-200 dark:bg-slate-600 animate-pulse"></span>
        <div className="h-[40px] w-full bg-slate-200 dark:bg-slate-600 animate-pulse"></div>
      </div>
      <div className="mt-5">
        <span className="block mb-[0.5rem] h-[14px] w-[70px] bg-slate-200 dark:bg-slate-600 animate-pulse"></span>
        <div className="h-[40px] w-full bg-slate-200 dark:bg-slate-600 animate-pulse"></div>
      </div>
      <div className="mt-5">
        <span className="block mb-[0.5rem] h-[14px] w-[70px] bg-slate-200 dark:bg-slate-600 animate-pulse"></span>
        <div className="h-[40px] w-full bg-slate-200 dark:bg-slate-600 animate-pulse"></div>
      </div>

      <div className="mt-6">
        <button
          className="w-[100%] hover:bg-btnHoverColor p-0 flex items-center justify-center gap-[10px] text-[14px] font-[500] uppercase disabled:cursor-not-allowed disabled:opacity-80"
          disabled
        >
          Next
        </button>
        <button
          type="button"
          className="w-[100%] bg-green-500 mt-4 p-0 flex items-center justify-center gap-[10px] text-[14px] font-[500]"
        >
          Pay with Lightning Wallet
        </button>
      </div>

      <div className="mt-7">
        <label htmlFor="">Pay with Others</label>
        <div className="mt-4 flex items-center gap-[10px] cursor-pointer border border-[#ced4da] dark:border-[#32394e] rounded h-[45px] px-[0.75rem] transition-all hover:bg-[#f6f6f6]">
          <img
            src="https://app.hami.live/static/media/meta-mask.908a7bd02e43908895d8.png"
            alt=""
            className="w-[25px]"
          />
          <span className="text-sm font-[500] ">Metamask</span>
        </div>
      </div>
    </div>
  );
}
