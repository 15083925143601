import React, { useState } from "react";
import OtpPinInput from "./OtpPinInput";

export default function ConfirmPinModal({
  setIsConfirmPinModalOpen,
  handleSubmit,
}) {
  const [pin, setPin] = useState({
    pin1: "",
    pin2: "",
    pin3: "",
    pin4: "",
    pin5: "",
  });

  return (
    <div
      id="confirm-pin-modal"
      className="fixed inset-0 flex bg-[#fff5] dark:bg-[#0005] items-center justify-center z-20 transition-all p-[15px]"
      onClick={(e) => {
        if (e.target === document.getElementById("confirm-pin-modal")) {
          setIsConfirmPinModalOpen(false);
        }
      }}
    >
      <div className="relative flex flex-col bg-[#fff] dark:bg-darkCardColor transition-all w-[100%] max-w-[380px] overflow-y-auto shadow-sm p-4 rounded shadow-[0_1rem_3rem_rgb(0_0_0_/_18%)]">
        <div>
          <label htmlFor=""> PIN</label>
          {/* Input Here */}
          <OtpPinInput pin={pin} setPin={setPin} />
          <span className="text-[13px] text-grayColor dark:text-darkGrayColor leading-[23px] block mt-3">
            If you don't have a  PIN. please create a PIN before
            initiating transactions.
          </span>
        </div>

        <button
          className="mt-4 disabled:cursor-not-allowed disabled:opacity-80 disabled:hover:bg-primaryColor"
          onClick={() => {
            let pinNumber = `${pin.pin1}${pin.pin2}${pin.pin3}${pin.pin4}${pin.pin5}`;
            handleSubmit(pinNumber);
            setIsConfirmPinModalOpen(false);
          }}
          disabled={
            !pin.pin1 || !pin.pin2 || !pin.pin3 || !pin.pin4 || !pin.pin5
          }
        >
          Confirm
        </button>
      </div>
    </div>
  );
}
