import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  HomeBalanceCard,
  HomeActivitiesTable,
  HomeWalletCard,
  HomeWalletSkeleton,
} from "../../features/Home";
import { PiPillFill } from "react-icons/pi";
import { CreatePaymentPageModal } from "../../features/PaymentPages";
import CreatePaymentLinkModal from "../../features/PaymentLinks/components/CreatePaymentLinkModal";
import { MdAddLink, MdGroupAdd } from "react-icons/md";
import { TbSettingsPlus } from "react-icons/tb";
import { GiTrade } from "react-icons/gi";
import { FaTools } from "react-icons/fa";
export default function HomePage() {
  const { currencies, isFetching } = useSelector((state) => state.currencies);
  const { user } = useSelector((state) => state.user);

  const navigate = useNavigate();

  const [paymentLinkModal, setPaymentLinkModal] = useState(false);
  const [paymentPagekModal, setPaymenPagekModal] = useState(false);

  return (
    <div className="lg:grid grid-cols-[auto_30%] gap-[80px] py-5 sm:py-6">
      <div>
        <HomeBalanceCard />

        {/* <div className="mt-8 sm:hidden">
          <div className="flex items-center justify-between mb-4">
            <h2 className="font-[600] text-base sm:text-lg ">Currencies</h2>
            <Link
              to="/currencies"
              className="text-sm font-medium text-blueColor"
            >
              View All
            </Link>
          </div>

          {isFetching ? (
            <div>
              {Array.from({ length: 3 }).map((_, index) => {
                return <HomeWalletSkeleton key={index} />;
              })}
            </div>
          ) : (
            <div>
              {currencies?.slice(0, 3)?.map((currency, index) => {
                return <HomeWalletCard key={index} {...currency} />;
              })}
            </div>
          )}
        </div> */}

        <div className="mt-10 hidden md:block">
          <div className="flex items-center gap-[10px] justify-between mb-2">
            <h3 className="font-[600] text-lg">Activity</h3>
            <Link
              to="/transactions"
              className="text-sm font-medium text-blueColor"
            >
              All activity
            </Link>
          </div>
          <HomeActivitiesTable />
        </div>
      </div>
      <div className="mt-[2em] sm:mt-0">
        {/* <HomeSendAgainCard /> */}
{/* 
        <div className="mt-8 hidden sm:block">
          <div className="flex items-center justify-between mb-4">
            <h2 className="font-[600] text-lg ">Currencies</h2>
            <Link
              to="/currencies"
              className="text-sm font-medium text-blueColor"
            >
              View All
            </Link>
          </div>

          {isFetching ? (
            <div>
              {Array.from({ length: 3 }).map((_, index) => {
                return <HomeWalletSkeleton key={index} />;
              })}
            </div>
          ) : (
            <div>
              {currencies?.slice(0, 3)?.map((currency, index) => {
                return <HomeWalletCard key={index} {...currency} />;
              })}
            </div>
          )}
        </div> */}

        <div className="mt-8">
          
            <div className="flex flex-row gap-1">
          
            <h2 className="font-[600] text-lg mb-4">
            Tools </h2>
            </div>
           
          <ul>
          <li
              className="flex items-center gap-3 mt-4 cursor-pointer"
              onClick={() => {
                if (user?.type === "merchant") {
                  setPaymenPagekModal(true);
                } else {
                  navigate("/upgrade/merchant");
                }
              }}
            >
              <div className="w-[40px] h-[40px] rounded bg-gray-200 dark:bg-primaryColor dark:text-black  text-lg flex items-center justify-center">
              <MdAddLink />
              </div>
              <div>
                <span className="font-medium text-[15px] block">
                Add RPC Url
                </span>
              </div>
            </li>
            <li
              className="flex items-center gap-3 mt-4 cursor-pointer"
              onClick={() => {
                if (user?.type === "merchant") {
                  navigate("/presale/create");
                } else {
                  navigate("/upgrade/merchant");
                }
              }}
            >
              <div className="w-[40px] h-[40px] rounded bg-gray-200 dark:bg-primaryColor dark:text-black text-lg flex items-center justify-center">
                <PiPillFill />
              </div>
              <div>
                <span className="font-medium text-[15px] block">Create Project</span>
              </div>
            </li>
            <li
              className="flex items-center gap-3 mt-4 cursor-pointer"
              onClick={() => {
                setPaymentLinkModal(true);
              }}
            >
              <div className="w-[40px] h-[40px] rounded bg-gray-200 dark:bg-primaryColor dark:text-black  text-lg flex items-center justify-center">
              <MdGroupAdd />              </div>
              <div>
                <span className="font-medium text-[15px] block">
                  Create Worker Wallet
                </span>
              </div>
            </li>
            <li
              className="flex items-center gap-3 mt-4 cursor-pointer"
              onClick={() => {
                if (user?.type === "merchant") {
                  setPaymenPagekModal(true);
                } else {
                  navigate("/upgrade/merchant");
                }
              }}
            >
              <div className="w-[40px] h-[40px] rounded bg-gray-200 dark:bg-primaryColor dark:text-black  text-lg flex items-center justify-center">
              <TbSettingsPlus />
              </div>
              <div>
                <span className="font-medium text-[15px] block">
                Create Wallet Settings
                </span>
              </div>
            </li>
            <li
              className="flex items-center gap-3 mt-4 cursor-pointer"
              onClick={() => {
                if (user?.type === "merchant") {
                  navigate("/create-invoice");
                } else {
                  navigate("/upgrade/merchant");
                }
              }}
            >
              <div className="w-[40px] h-[40px] rounded bg-gray-200 dark:bg-primaryColor dark:text-black  text-lg flex items-center justify-center">
              <GiTrade />
              </div>
              <div>
                <span className="font-medium text-[15px] block">
                  Create Market Making
                </span>
              </div>
            </li>
          </ul>
        </div>
        {paymentPagekModal && (
          <CreatePaymentPageModal
            setIsCreatePaymentPageModalOpen={setPaymenPagekModal}
          />
        )}
        {paymentLinkModal && (
          <CreatePaymentLinkModal
            setIsCreatePaymentLinkModalOpen={setPaymentLinkModal}
          />
        )}
      </div>
    </div>
  );
}
